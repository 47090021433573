import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
// components
import Layout from "../components/Layouts/layout"
import SEO from "../components/seo"
import {
    FirstScreen,
    ServicesAdvantages,
    HowItWorksReserve,
    Components,
    InteractiveReserve,
    StagesOfWork,
    Garants
} from '../components/ServicesAndPrices'
import { FormOrder, Breadcrumbs } from '../components/Global'
// content
import YAMLGlobal from "../content/ua/global.yaml"
import YAMLService from "../content/ua/service.yaml"

const ServicesReservePage = ({location}) => {    
    const serviceLocal = {
        langBase: "/ua",
        YAMLGlobal,
        YAMLContent: YAMLService
    }
    
    const data = useStaticQuery(graphql`
        query {
            seo: directusPricesReserveSeo {
                title_ua
                description_ua
            }
            firstscreen: allDirectusPricesFirstscreen {
                edges {
                    node {
                        reserve_description_ua
                        reserve_title_ua
                        reserve_image {
                            localFile {
                                childImageSharp {
                                    fluid(maxWidth: 1600) {
                                        ...GatsbyImageSharpFluid
                                    }
                                }
                            }
                        }
                    }
                }
            },
            advantages: allDirectusPricesReserveAdvantage {
                edges {
                    node {
                        id
                        title_ua
                        description_ua
                    }
                }
            },
            slides: allDirectusPricesReserveComponent {
                edges {
                    node {
                        id
                        description_ua
                        title_ua
                        image {
                            localFile {
                                childImageSharp {
                                    fluid(maxWidth: 400) {
                                        ...GatsbyImageSharpFluid
                                    }
                                }
                            }
                        }
                        logos {
                            id
                            localFile {
                                childImageSharp {
                                    fluid(maxWidth: 400) {
                                        ...GatsbyImageSharpFluid
                                    }
                                }
                            }
                        }
                    }
                }
            },
            stages: allDirectusStepsWorkRu(sort: {fields: sort}) {
                edges {
                    node {
                        id
                        list_ua
                        sort
                        title_ua
                        image {
                            localFile {
                                childImageSharp {
                                    fluid(maxWidth: 700) {
                                        ...GatsbyImageSharpFluid
                                    }
                                }
                            }
                        }
                    }
                }
            },
            interactive: allDirectusPricesservicesreserve {
                edges {
                    node {
                        reserve_price
                    }
                }
            },
            garants: allDirectusPricesGuarantee {
                edges {
                    node {
                        id
                        description_ua
                        image {
                            localFile {
                                childImageSharp {
                                    fluid(maxWidth: 400) {
                                        ...GatsbyImageSharpFluid
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    `)
    
    const list = [
        {
            link: "/",
            title: YAMLGlobal.breadCrumbs.main
        },
        {
            link: '/services-home',
            title: YAMLGlobal.menu.services.reserve
        }
    ]
    
    return(
        <Layout local={serviceLocal} location={location}>
            <SEO title={data.seo.title_ua} description={data.seo.description_ua} lang='ua' />
            <Breadcrumbs list={list}/>
            <FirstScreen content={{
                title: data.firstscreen.edges[0].node.reserve_title_ua,
                text: data.firstscreen.edges[0].node.reserve_description_ua,
                img: data.firstscreen.edges[0].node.reserve_image.localFile?.childImageSharp.fluid
            }} />
            <ServicesAdvantages advantages={data.advantages.edges}/>
            <HowItWorksReserve/>
            <Components background="gray" slides={data.slides.edges}/>
            <InteractiveReserve interactive={data.interactive.edges[0].node}/>
            <StagesOfWork stages={data.stages.edges}/>
            <Garants waveMob={true} garants={data.garants.edges}/>
            <FormOrder wave="white"/>
        </Layout>
    )
}

export default ServicesReservePage;